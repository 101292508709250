// extracted by mini-css-extract-plugin
export var ArtistDescription = "ERO-module--ArtistDescription--wLDhH";
export var ArtistInfos = "ERO-module--ArtistInfos--FaiH3";
export var ButtonWrapper = "ERO-module--ButtonWrapper --pGX6N";
export var CardWrapper = "ERO-module--CardWrapper--4peZb";
export var CarrouselWrapper2 = "ERO-module--CarrouselWrapper2--SczNV";
export var Citations = "ERO-module--Citations--Vif4Y";
export var DescriptionWrapper = "ERO-module--DescriptionWrapper--2JVwh";
export var ImageWrapper = "ERO-module--ImageWrapper--tl7x6";
export var LinkWrapper = "ERO-module--LinkWrapper--IUm5u";
export var MobileProtrait = "ERO-module--MobileProtrait--D2LZf";
export var More = "ERO-module--More--0AJa+";
export var MoreButton = "ERO-module--MoreButton--3uhmj";
export var NameWrapper = "ERO-module--NameWrapper--fPp-7";
export var PdpWrapper = "ERO-module--PdpWrapper--PCIUj";
export var PhotosWrapper = "ERO-module--PhotosWrapper---UdRV";
export var ProfilWrapper = "ERO-module--ProfilWrapper--6jGn6";
export var TitleWrapper = "ERO-module--TitleWrapper--NGo+i";
export var Wrapper = "ERO-module--Wrapper--+yePC";