
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./ERO.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import EROPhoto1 from "../../../../res/Photos site/ERO/ero.png"
import EROPhoto2 from "../../../../res/Photos site/ERO/People-Magazine-comes-to-the-FUN-1983.-Photo-courtesy-of-Patti-Astor.-759x1024.jpg"
import EROPhoto3 from "../../../../res/Photos site/ERO/3.jpg"
import EROPhoto4 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Ero - Untitled - 1984.png"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/ero/portrait.jpg'
import PastPresent1 from "../../../../res/ero/past-present-1.jpeg"
import PastPresent2 from "../../../../res/ero/past-present-2.jpg"
import PastPresent3 from "../../../../res/ero/past-present-3.jpg"
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "ERO",
  name: "ERO",
  description:"Dominique Philbert alias \"ERO\" (Ever Rocking On), est l'un des plus jeunes artistes pionniers du mouvement graffiti de New York City des années 1980. Tout au long de sa vie, les oeuvres d'ERO ont fait partie d'expositions historiques, comme à la Fun Gallery de Patti Astor, du célèbre \"Five-Man Show\" qui, en 1983, a fait le tour du Japon (les autres artistes étaient Fab 5 Freddy, Zephyr, Futura 2000 et Dondi White), et de nombreuses autres expositions à travers le monde. Plus récemment, le travail d'ERO a été présenté à la GALLERY 151 dans trois expositions distinctes : en 2007 aux côtés de Fab 5 Freddy, Jean-Michel Basquiat, Keith Haring, Kenny Scharf et Koor dans le cadre de l'exposition \"The Wild Style\", en 2008 dans le cadre d'une exposition solo, et en 2009 aux côtés de LA II  et SHARP dans l'exposition \"Green Style\". Sa mort en 2011 fut une perte importante pour la communauté artistique de New York.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de ERO.",
  photos: [
    { src: PastPresent1, name: "ERO" },
    { src: PastPresent2, name: "ERO" },
    { src: PastPresent3, name: "ERO" },
  ],
  citations: []
};

const infos2 = {
  photos: [
    // { src: DanielArsham1, name: "Daniel Arsham", artist: "Daniel Arsham (1980)", title:"Ash Eroded Film Reel, 2013", desc1:"Cendres volcaniques, verre brisé, hydrostone", desc2:"Volcanic ash, shattered glass, hydrostone", desc3:"35 x 35cm", desc4:"14 7⁄8 x 14 7⁄8 in.", desc5:"Sculpture originale, pièce unique", desc6:"Original sculpture, unique work", desc7: "- Collection particulière.\n- Ron Mandos Gallery, Pays-Bas.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>", desc10:"<h4>- Un certificat de Ron Mandos Gallery sera remis à l'acquéreur.</h4>"  }
    { src: EROPhoto4, name: "ERO", artist:"ERO\n(Dominique Philbert dit) (1967 - 2011)", title:"Untilted, 1984", desc3: "120 x 254 cm", desc4:" 47 1/4 x 100 in.", desc7: "- Collection particulière." },
  ],
};

const ERO = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>ERO <h2 style={{paddingLeft: "16px"}}>(Dominique Philbert - Américain)</h2></h1>
          <h3 className={NameWrapper} >1967 - 2011</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default ERO;